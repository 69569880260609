/**
* Generated automatically at built-time (2024-08-30T15:19:13.966Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { getComponentClassName } from "@utils/strings";
import { getScreenTemplates, mergeHelmet } from "../utils";
export default ({screen, siteContext})=>{
const helmet = mergeHelmet(siteContext.helmet, screen.helmet);
const pageId = "udekokken-inspiration";
return {[pageId]: {id: pageId,helmet,className: getComponentClassName(pageId),items: getScreenTemplates(screen)}};
}